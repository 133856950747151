import React, { useState, useEffect } from 'react';
import logoTopAdmin from '../../assets/images/medosphere-studio-logo-blue.svg';
import './dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import TableHeader from '../../components/TableHeader';
import CustomerTableHeader from '../../components/CustomerTableHeader';
import { PageLoader } from '../../components/page-loader';
import LogoutDialog from '../../components/LogoutDialog';
import TableBody from '../../components/TableBody';
import ProfileButton from '../../components/ProfileButton';
import {orderListUrl, assignApiEndpoint} from "../../config";
import OptionsContainer from '../../components/OptionsContainer';
import { LogoInline } from '../../components';

library.add(faCalendar, faSearch, faUser);

interface Video {
  id: number;
  title: string;
  content: string;
  video_url: string;
  thumbnail_url: string;
  preview: string;
  created_at: string;
  fileName: string;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile_image: string;
}

interface Attachment {
  url: string;
  name: string;
  type: string;
}

interface OrderData {
  id: number;
  how_use: string;
  blurring: boolean;
  bigger: boolean;
  script: string;
  notes: string;
  status: string;
  cost: number | null;
  due_date: string | null;
  attachments: Attachment[];
  video: Video;
  user: User;
  assigned_to_user: User | null;
}

interface ApiResponse {
  data: OrderData[];
  total: number;
  page: number;
  page_size: number;
  total_pages: number;
}

interface ProductOrder {
  orderId: number;
  customer: string;
  customerImg: string
  contentType: string;
  dueDate: string;
  status: string;
  state: string;
  cost: string;
  assignee: string;
  blurring: boolean;
  customerID: string;
  bigger: boolean;
  script: string;
  attachments: Attachment[];
  video: Video;
}

export default function Dashboard() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Open');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [sortBy, setSortBy] = useState('orderId'); // Default sorting by orderId
  const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order
  const [apiOrders, setApiOrders] = useState<ProductOrder[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const userData = user;
  const userId = user?.sub?.split('|')[1];
  const userImage = user?.picture;
  const userEmail = user?.email;
  const userName = user?.nickname;
  const [isLoading, setLoading] = useState(true);

  const isAdmin = user?.role === 'admin';

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const isValidURL = (url: string | URL) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: string) => {
    setDropdownOpen(false);
    setSelectedOption(option);
    setSearchTerm('');
    setCurrentPage(1);
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const transformApiData = (apiData: ApiResponse): ProductOrder[] => {
    //console.log(apiData);
    return apiData.data.map((orderData) => ({
      orderId: orderData.id,
      customer: `${orderData.user.first_name} ${orderData.user.last_name}`,
      customerImg: `${orderData.user.profile_image}`,
      customerID: `${orderData.user.id}`,
      contentType: orderData.how_use,
      dueDate: orderData.due_date || '- -',
      status: orderData.status,
      state: 'test', // Add your logic for determining state
      cost: orderData.cost ? orderData.cost.toFixed(2) : '- -',
      assignee: orderData.assigned_to_user ? `${orderData.assigned_to_user.first_name} ${orderData.assigned_to_user.last_name}` : 'Not Assigned',
      blurring: orderData.blurring,
      bigger: orderData.bigger,
      id: orderData.id,
      how_use: orderData.how_use,
      note: orderData.notes,
      script: orderData.script,
      attachments: orderData.attachments || [],
      video: { // Map the video data to the Video interface
        id: orderData.video.id,
        title: orderData.video.title,
        content: orderData.video.content,
        video_url: orderData.video.video_url,
        thumbnail_url: orderData.video.thumbnail_url,
        preview: orderData.video.preview,
        created_at: orderData.video.created_at,
        fileName: orderData.video.fileName,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch( orderListUrl, {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          const transformedApiData = transformApiData(data);
          setApiOrders(transformedApiData);
        } else {
          console.error('Unexpected response format:', contentType);
        }
      } catch (error) {
        console.error('Error fetching data from the API', error);
      } finally {
        setLoading(false); // Set loading state to false regardless of success or failure
      }
    };

    fetchData();
  }, [isAuthenticated, accessToken]);

  const filteredOrders = selectedOption === 'All'
  ? apiOrders.filter(order =>
    order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.contentType.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.orderId.toString().includes(searchTerm.toLowerCase()) ||
    (order.assignee.toLowerCase().includes(searchTerm.toLowerCase()))
  )
  : selectedOption === 'Open'
    ? apiOrders.filter(order =>
      (order.state.toLowerCase() === 'open' || order.status.toLowerCase() === 'open') &&
      (
        order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.contentType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.orderId.toString().includes(searchTerm.toLowerCase()) ||
        (order.assignee.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    )
    : selectedOption === 'Completed'
      ? apiOrders.filter(order =>
        ((order.state.toLowerCase() === 'completed' || order.state.toLowerCase() === 'complete') ||
         (order.status.toLowerCase() === 'completed' || order.status.toLowerCase() === 'complete')) &&
        (
          order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.contentType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.orderId.toString().includes(searchTerm.toLowerCase()) ||
          (order.assignee.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      )
      : selectedOption === 'Closed'
        ? apiOrders.filter(order =>
          (order.state.toLowerCase() === 'cancelled' || order.state.toLowerCase() === 'refunded' ||
            order.status.toLowerCase() === 'cancelled' || order.status.toLowerCase() === 'refunded') &&
          (
            order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.contentType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.orderId.toString().includes(searchTerm.toLowerCase()) ||
            (order.assignee.toLowerCase().includes(searchTerm.toLowerCase()))
          )
        )
        : [];
        
  const handleSortChange = (criteria: string) => {
    // Toggle sorting order if the same criteria is clicked again
    setSortOrder((prevOrder) => (sortBy === criteria ? (prevOrder === 'asc' ? 'desc' : 'asc') : 'asc'));
    setSortBy(criteria);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSearchTerm('');
  };

  const handleAssignClick = async (orderId: number, assignedUserId: string) => {
    try {
      const response = await fetch(assignApiEndpoint(orderId), {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          assigned_to: assignedUserId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Handle success, e.g., update state or perform additional actions
    } catch (error) {
      console.error('Error assigning order:', error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const sortedOrders = filteredOrders.slice().sort((a, b) => {
    // Sorting logic based on the selected criteria and order
    if (sortBy === 'orderId' || sortBy === 'dueDate') {
      // Convert the values to numbers for numeric comparison
      const aValue = sortBy === 'orderId' ? Number(a[sortBy]) : Date.parse(a[sortBy]);
      const bValue = sortBy === 'orderId' ? Number(b[sortBy]) : Date.parse(b[sortBy]);

      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (sortBy === 'customer' || sortBy === 'contentType' || sortBy === 'status' || sortBy === 'cost' || sortBy === 'assignee') {
      return sortOrder === 'asc' ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
    }

    return 0;
  });

  const totalPages = Math.ceil(sortedOrders.length / itemsPerPage);

  return (
    <div className={`dashboard ${isAdmin ? 'admin-theme-dash' : 'user-theme-dash'}`}>
      <div className="dashboard-header">
        <div className="top-nav">
          <div className="logo-container">
            <Link to="/dashboard" style={{ textDecoration: 'none' }}>
              <LogoInline />
            </Link>
          </div>
          <Link to="/upload" style={{ textDecoration:'none' }}>
              <div style={{
                  padding: '10px 20px',
                  color: 'white',
                  backgroundColor: '#3F51B5',
                  borderRadius: '5px',
                }}
              >
                Upload
              </div>
          </Link>
          <ProfileButton
            isDropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            userImage={userImage}
            isValidURL={isValidURL}
            navigate={navigate}
            openModal={openModal}
            userID={userId}
            userEmail={userEmail}
            userName={userName}
          />
        </div>
        <div className='title-container'>
          <h1 className={`page-title ${isAdmin ? 'admin-title' : 'user-title'} `}>Dashboard</h1>
          <div className="calendar">
            <a href="/">
              <FontAwesomeIcon style={{paddingLeft: '20px' }} className={`${isAdmin ? 'admin-date' : 'user-date'} `} icon="calendar"/>
            </a>
            <span className={`${isAdmin ? 'admin-link' : 'user-link'}`}> {currentDate}</span>
          </div>
        </div>
      </div>
      <div className={`dashboard-content ${isAdmin ? 'admin-theme-dash-content' : 'user-theme-dash-content'}`}>
        {/* Your main content goes here */}
        <OptionsContainer
          handleOptionClick={handleOptionClick}
          selectedOption={selectedOption}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isAdmin={isAdmin}
        />
        {isLoading ? (<PageLoader />)  
        :(<div className="table-container">
          {selectedOption === 'Open' && (
            <>
              {sortedOrders.length > 0 && (
                <>
                  {userData?.role === 'admin' ? (
                    <table className='orders-table'>
                      <TableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} isAdmin={isAdmin}/>
                      <TableBody sortedOrders={sortedOrders}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  ) : (
                    <table className='orders-table'>
                      <CustomerTableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} />
                      <TableBody sortedOrders={sortedOrders}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  )}
                </>
              )}
              {sortedOrders.length === 0 && (
                <table className='orders-table'>
                  <TableBody sortedOrders={sortedOrders}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                    />
                </table>
              )}
            </>
          )}
          {selectedOption === 'Closed' && (
            <>
              {sortedOrders.length > 0 && (
                <>
                  {userData?.role === 'admin' ? (
                    <table className='orders-table'>
                      <TableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} isAdmin={isAdmin}/>
                      <TableBody sortedOrders={sortedOrders}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  ) : (
                    <table className='orders-table'>
                      <CustomerTableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} />
                      <TableBody sortedOrders={sortedOrders}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  )}
                </>
              )}
              {sortedOrders.length === 0 && (
                <table className='orders-table'>
                  <TableBody sortedOrders={sortedOrders}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                    />
                </table>
              )}
            </>
          )}
          {selectedOption === 'Completed' && (
            <>
              {sortedOrders.length > 0 && (
                <>
                  {userData?.role === 'admin' ? (
                    <table className='orders-table'>
                      <TableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} isAdmin={isAdmin}/>
                      <TableBody sortedOrders={sortedOrders}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  ) : (
                    <table className='orders-table'>
                      <CustomerTableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} />
                      <TableBody sortedOrders={sortedOrders}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  )}
                </>
              )}
              {sortedOrders.length === 0 && (
                <table className='orders-table'>
                  <TableBody sortedOrders={sortedOrders}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                    />
                </table>
              )}
            </>
          )}
          {selectedOption === 'All' && (
            <>
              {sortedOrders.length > 0 && (
                <>
                  {userData?.role === 'admin' ? (
                    <table className='orders-table'>
                      <TableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} isAdmin={isAdmin}/>
                      <TableBody sortedOrders={sortedOrders}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  ) : (
                    <table className='orders-table'>
                      <CustomerTableHeader handleSortChange={handleSortChange} sortBy={sortBy} sortOrder={sortOrder} />
                      <TableBody sortedOrders={sortedOrders}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                        />
                    </table>
                  )}
                </>
              )}
              {sortedOrders.length === 0 && (
                <table className='orders-table'>
                  <TableBody sortedOrders={sortedOrders}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handleAssignClick={(orderId: number) => handleAssignClick(orderId, userId || '')}
                    />
                </table>
              )}
            </>
          )}
        </div>)}
        <div className="pagination-container">
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      </div>
      <LogoutDialog isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
