import React from 'react'
import ClearIcon from '../../../assets/images/clear-icon.svg'

interface InputProps {
  label?: string | null
  type?: 'text' | 'email' | 'password' | 'numeric'
  id?: string
  name: string
  value: string
  onChange: (e: any) => void
  required?: boolean 
  disabled?: boolean
  error?: string | null
  clearInput?: boolean
  onClear?: () => void
}

const Input = ({
  label = null,
  type = 'text',
  id,
  name,
  value,
  onChange = () => {},
  required = false,
  disabled = false,
  error = null,
  clearInput = false,
  onClear = () => {}
}: InputProps) => {

  return (
    <div className="form-group">
      {!!label ? <label>{label}</label> : null}

      <div className="input-field">
        <input
          className={`input ${error ? 'input-error' : ''}`}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />

        {!!clearInput ? (
          <button className="clear-icon" onClick={onClear}>
            <img src={ClearIcon} alt="" />
          </button>
        ): null}
      </div>
    </div>
  )
}

export default Input