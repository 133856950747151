import React from 'react'
import MedosphereLogo from '../../assets/images/medosphere_logo.svg'
import './logo.css'

const Logo = () => (
  <div className='logo'>
    <img src={MedosphereLogo} alt='powered by curiosity' />
    <span>Studio</span>
  </div>
)

export default Logo