import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OptionsContainerProps {
  handleOptionClick: (option: string) => void;
  selectedOption: string;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  isAdmin: boolean;
}


const OptionsContainer: React.FC<OptionsContainerProps> = ({
  handleOptionClick,
  selectedOption,
  searchTerm,
  setSearchTerm,
  isAdmin,
}) => {
  return (
    <div className={`options-container ${isAdmin ? 'admin-theme-options-container' : 'user-theme-options-container'}`}>
      <div
        onClick={() => handleOptionClick('Open')}
        className={`menu-item ${selectedOption === 'Open' ? 'selected' : ''} ${isAdmin ? 'admin-theme-option' : 'user-theme-option'}`}
      >
        Open
      </div>
      <div
        onClick={() => handleOptionClick('Completed')}
        className={`menu-item ${selectedOption === 'Completed' ? 'selected' : ''} ${isAdmin ? 'admin-theme-option' : 'user-theme-option'}`}
      >
        Completed
      </div>
      <div
        onClick={() => handleOptionClick('Closed')}
        className={`menu-item ${selectedOption === 'Closed' ? 'selected' : ''} ${isAdmin ? 'admin-theme-option' : 'user-theme-option'}`}
      >
        Closed
      </div>
      <div
        onClick={() => handleOptionClick('All')}
        className={`menu-item ${selectedOption === 'All' ? 'selected' : ''} ${isAdmin ? 'admin-theme-option' : 'user-theme-option'}`}
      >
        All
      </div>
      <div className={`search-container ${isAdmin ? 'admin-theme-option' : 'user-theme-option'}`} style={{ right: '10%' }}>
        <input
          type="text"
          placeholder=""
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FontAwesomeIcon icon="search" style={{ color: 'black' }} />
      </div>
    </div>
  );
};

export default OptionsContainer;
