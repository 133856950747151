// LogoutDialog.tsx

import React from 'react';
import {
  LogoutButton,
  Logo,
} from '.';

interface LogoutDialogProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({ isModalOpen, closeModal }) => {
  return (
    <dialog id="modal" className={`modal ${isModalOpen ? 'open' : ''}`} open={isModalOpen} style={{paddingTop: '5%' ,backgroundColor: 'white', color: 'black'}}>
      <Logo />
      <h1>Confirm Log Out</h1>
      <p>Are you sure you want to log out?</p>
      <div style={{ textAlign: 'center' }}>
        <LogoutButton />
        <p></p>
        <button id="closeModal" className="modal-close-btn" onClick={closeModal} style={{ width: '100%' }}>
          Close
        </button>
      </div>
    </dialog>
  );
};

export default LogoutDialog;
