import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from '../index'

const LogoutButton = () => {
	const { logout } = useAuth0();

  const handleLogout = () => logout({
    logoutParams: {
      returnTo: window.location.origin
    }
  })

	return (
    <Button 
      text="Log Out"
      type="outline"
      onClick={handleLogout}
    />
	);
};

export default LogoutButton;