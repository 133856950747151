import React from 'react'

interface TextareaProps {
  label?: string | null
  id?: string
  name: string
  value: string
  onChange: (e: any) => void
  required?: boolean 
  disabled?: boolean
  error?: string | null
  rows?: number
}

const Textarea = ({
  label = null,
  id,
  name,
  value,
  onChange = () => {},
  required = false,
  disabled = false,
  error = null,
  rows = 3,
}: TextareaProps) => {

  return (
    <div className="form-group">
      {!!label ? <label>{label}</label> : null}

      <div className="input-field">
        <textarea
          className={`input ${error ? 'input-error' : ''}`}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          rows={rows}
        />
      </div>
    </div>
  )
}

export default Textarea