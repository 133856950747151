import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo_connected.svg';
import './signup.css';

export default function EditorSignup() {
  // const nav = useNavigate();
  // const [user, setUser] = useState({
  //   username: '',
  //   email: '',
  //   password: '',
  //   confirmEmail: '',
  //   confirmPassword: '',
  // });

  // const specialChars = '!@#$%^&*()+=';
  // const passwordRecs = [
  //   `MUST contain at least 8 characters (12+ recommended)`,
  //   `MUST contain at least one uppercase character`,
  //   `MUST contain at least one lowercase character`,
  //   `MUST contain at least one number`,
  //   `MUST contain at least one special character ${specialChars}`,
  // ];

  // const handleSubmit = (event: React.FormEvent) => {
  //   event.preventDefault();

  //   // Check if fields are empty
  //   if (!user.username || !user.email || !user.password || !user.confirmEmail || !user.confirmPassword) {
  //     alert('Please fill in all fields');
  //     return;
  //   }

  //   // Check if email and confirmEmail match
  //   if (user.email !== user.confirmEmail) {
  //     alert('Email and Confirm Email do not match');
  //     return;
  //   }

  //   // Check if password and confirmPassword match
  //   if (user.password !== user.confirmPassword) {
  //     alert('Password and Confirm Password do not match');
  //     return;
  //   }

  //   // Validate password against criteria
  //   if (!validatePassword(user.password)) {
  //     alert('Password does not meet the criteria');
  //     return;
  //   }

  //   // Add signup logic for editors here
  //   // ...
  //   // Redirect to another page after signup
  //   nav('/profile', { state: { user } });
  // };

  // const validatePassword = (password: string) => {
  //   // Check if the password meets all criteria
  //   return (
  //     password.length >= 8 &&
  //     /[A-Z]/.test(password) &&
  //     /[a-z]/.test(password) &&
  //     /\d/.test(password) &&
  //     new RegExp(`[${specialChars}]`).test(password)
  //   );
  // };

  // const handleInputChange = (field: string, value: string) => {
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     [field]: value,
  //   }));
  //   console.log(user);
  // };

  return (
    <div className="App">
      {/* <header className="App-header-signup">
        <img src={logo} className="App-logo-signup" alt="logo" />
        <p style={{ color: '#9900FF' }}>Sign Up for Medosphere Alpha</p>
        <div className="App-signup-box">
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={user.username}
              onChange={(e) => handleInputChange('username', e.target.value)}
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={user.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              required
            />

            <label htmlFor="confirmEmail">Confirm Email:</label>
            <input
              type="email"
              id="confirmEmail"
              name="confirmEmail"
              value={user.confirmEmail}
              onChange={(e) => handleInputChange('confirmEmail', e.target.value)}
              required
            />

            <div className="password-requirements">
              <p>Password Requirements:</p>
              <ul>
                {passwordRecs.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </div>

            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={user.password}
              onChange={(e) => handleInputChange('password', e.target.value)}
              required
            />

            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
              required
            />

            <button type="submit">Sign Up</button>
          </form>
        </div>
      </header>
      ... rest of the component ... */}
    </div>
  );
}
