import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthWrapper = ({ Component, userProps = {} }: any) => {
  const { user } = useAuth0();
  const userEmail = user?.email?.toString() || 'example';
  const userName = user?.nickname?.toString() || 'example';

  const props = {
    ...userProps,
    currentUser: {
      username: userName,
      email: userEmail,
    },
  };

  return <Component {...props} />;
};

export default AuthWrapper;