import React from 'react';
import WebARComponent from '../../components/WebARComponent';

const WebAREditor: React.FC = () => {

  return (
    <div>
      <WebARComponent />
    </div>
  );
};

export default WebAREditor;