import React, { useState } from 'react';
import logoTop from '../../assets/images/medosphere-studio-logo.svg';
import './videos.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoutDialog from '../../components/LogoutDialog';
import ProfileButton from '../../components/ProfileButton';

export default function Videos() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useAuth0();

  const userImage = user?.picture;

  const navigate = useNavigate();

  const isValidURL = (url: string | URL) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="videos">
      <div className="videos-header">
        <div className="top-nav">
          <div className="logo-container">
            <Link to="/dashboard">
              <img src={logoTop} alt="logo" />
            </Link>
          </div>
          {/* <ProfileButton
            isDropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            userImage={userImage}
            isValidURL={isValidURL}
            navigate={navigate}
            openModal={openModal}
          /> */}
        </div>
        <div className='title-container'>
          <h1 className='page-title'>Videos</h1>
          <div className="calendar">
            <a href="/">
              <FontAwesomeIcon icon="calendar" style={{ color: 'white', paddingLeft: '20px' }} />
            </a>
            <span className="App-link"> {currentDate}</span>
          </div>
        </div>
      </div>
      <div className="videos-content">
        <div style={{flex: 1, color: 'white', textAlign: 'center'}}>
          <h1>Saved Videos</h1>
          <h3>Coming Soon</h3>
        </div>
      </div>
      <LogoutDialog isModalOpen={isModalOpen} closeModal={closeModal} />
      <div style={{ color: 'grey', position: 'absolute', bottom: '1%', right: '9%', fontSize: '17px' }}>
        Web App 1.0
      </div>
    </div>
  );
}
