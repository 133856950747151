import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  LogoutButton,
  Modal,
  Logo,
  ScreenContainer,
  Input,
  Textarea,
} from '../../components'
import './login.css';

export default function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLoginRedirect = () => loginWithRedirect({
		authorizationParams: {
      redirect_uri: window.location.origin + '/verifyLicense'
    }
	})

  const handleContactClick = () => {
    setIsContactModalOpen(true);
  };

  const handleModalClose = () => {
    setIsContactModalOpen(false);
  };

  const handleSendContactForm = () => {
      //console.log("Contact Us Pressed")
  };

  const onClick = () => navigate('/dashboard')
  
  return (
    <ScreenContainer>
      <>
        <p className="welcome-label">
          Welcome
        </p>

        <div className="login-logo">
          <Logo />
        </div>

        <div className="app-buttons">
          {isAuthenticated ? (
            <>
              <Button text="Go To Studio"  onClick={onClick} />
              <LogoutButton />
            </>
          ) : (
            <>
              <Button
                onClick={handleLoginRedirect}
                text="Get Started"
              />
              <Button
                type="outline"
                onClick={handleLoginRedirect}
                text="I Already Have An Account"
              />
            </>
          )}
          <div className="need-help">
            <Button
              type="underline"
              onClick={handleContactClick}
              text="Need Help?"
            />
          </div>
        </div>
      </>

      <Modal isOpen={isContactModalOpen} onClose={handleModalClose}>
        <h3 className="modal-label">Contact Us!</h3>
        <div className="modal-subtitle">Send us your questions, comments and contact information. We'll get in touch soon!</div>
        <div className="modal-form">
          <Input
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Textarea
            label="Message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div className="modal-options">
          <Button
            type="outline"
            text="Cancel"
            onClick={handleModalClose}
          />
          <Button 
            text="Submit"
            onClick={handleSendContactForm}
          />
        </div>
      </Modal>
    </ScreenContainer>
  );
}