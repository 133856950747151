import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ProfileButtonProps {
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  userImage: string | undefined;
  userEmail: string | undefined;
  userName: string | undefined;
  userID: string | undefined;
  isValidURL:  (url: string | URL) => boolean;
  navigate: (path: string) => void;
  openModal: () => void;
}

const ProfileButton: FC<ProfileButtonProps> = ({
  isDropdownOpen,
  toggleDropdown,
  userID,
  userImage,
  userEmail,
  userName,
  isValidURL,
  navigate,
  openModal,
}) => {
  return (
    <div className="profile-button-container">
      <button className={`dropdown-options ${isDropdownOpen ? 'active' : ''}`} onClick={toggleDropdown}>
        {userImage && isValidURL(userImage) ? (
          <img className="UserImage" src={userImage} alt="User" style={{ width: '15px' }} />
        ) : (
          <FontAwesomeIcon icon="user" />
        )}
        {isDropdownOpen ? ' Profile ▲' : ' Profile ▼'}
      </button>
      {isDropdownOpen && (
        <div className="dropdown-contents">
          <div style={{textAlign: 'center'}}>
            <p style={{marginLeft: '5%', marginBottom: '5%', fontSize:'15px', textAlign: 'left'}}>User ID: {userID}</p>
            {userImage && isValidURL(userImage) ? (
              <img className="UserImage" src={userImage} alt="User" style={{ width: '40%' }} />
            ) : (
              <FontAwesomeIcon icon="user" />
            )}
            <div>
              <h5>{userName}</h5>
              <p style={{fontSize:'12px'}}> {userEmail} </p>
            </div>
            <button className="dropdown-button" style={{ width: '100%', color: 'black' }} id="openModal" onClick={openModal}>
              Log Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
