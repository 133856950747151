import React from "react";

// Pages
import Home from "../pages/home/home";
import Landing from "../pages/landing/landing";
import Channel from "../pages/channel/channel";
import Login from "../pages/login/login";
import Signup from "../pages/signup/signup";
import VerifyLicense from "../pages/verifyLicense/verifyLicense";
import EditorSignup from "../pages/signup/editorSignup";
import Profile from "../pages/profile/profile";
import DoctorProfile from "../pages/profile/doctorProfile";
import Dashboard from "../pages/dashboard/dashboard";
import Order from "../pages/order/order";
import Videos from "../pages/videos/videos"
import Upload from "../pages/upload/upload"
import WebAREditor from "../pages/webar/webar"

// Components
import Editor from "../CreativeEditorSDK";
import AuthWrapper from "../components/Auth/AuthWrapper";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/landing",
    element: <Landing />,
  },
  {
    path: "/channel",
    element: <Channel />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/verifyLicense",
    element: <VerifyLicense />,
  },
  {
    path: "/editorSignup",
    element: <EditorSignup />,
  },
  {
    path: "/profile",
    element: <AuthWrapper Component={Profile} />,
  },
  {
    path: "/doctorProfile",
    element: <AuthWrapper Component={DoctorProfile} />,
  },
  {
    path: "/dashboard",
    element: < Dashboard />,
  },
  {
    path: "/editor",
    element: <Editor />,
  },
  {
    path: "/order",
    element: <Order />
  },
  {
    path: "/videos",
    element: <Videos />
  },
  {
    path: "/upload",
    element: <Upload />
  },
  {
    path: "/webareditor",
    element: <WebAREditor />
  },
];

export default routes;