import React, { useState } from 'react';
import logoTop from '../../assets/images/medosphere-studio-logo-blue.svg';
import './order.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutDialog from '../../components/LogoutDialog';
import ProfileButton from '../../components/ProfileButton';
import UploadButton from '../../components/UploadButton';
import { LogoInline } from '../../components';

library.add(faCalendar);

interface Attachment {
  url: string | undefined;
  name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
  type: string;
}

interface ImageAttachment extends Attachment {
  type: 'image';
}

interface VideoAttachment extends Attachment {
  type: 'video';
}

interface OtherAttachment extends Attachment {
  type: 'other';
}

export default function Order() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Requirements');
  const accessToken = localStorage.getItem('accessToken');

  const [duration, setDuration] = useState<number>(0);
  const ratePerMinute = 9.99;

  const [isModalOpen, setModalOpen] = useState(false);

  const { user } = useAuth0();
  const userID = user?.sub?.split('|')[1];
  const userImage = user?.picture;
  const userEmail = user?.email;
  const userName = user?.nickname;

  const [attachmentsExist, setAttachmentsExist] = useState(false);

  // ... (existing code)

  const fetchOrderAttachments = () => {
    // Logic to fetch attachments
    const attachments = orderData.attachments || [];
    setAttachmentsExist(attachments.length > 0);
  };


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const isValidURL = (url: string | URL) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };


  const calculateCost = () => {
    return duration * ratePerMinute;
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: string) => {
    // Close the dropdown after handling the click
    setDropdownOpen(false);
    setSelectedOption(option);
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const orderData = location.state?.order || {};

  const goToEditor = () => {
    fetchOrderAttachments();
    //navigate(`/editor?videoUrl=${orderData.video.video_url}&videoID=${orderData.video.id}`);
    navigate(`/editor?videoUrl=${orderData.video.video_url}&videoID=${orderData.video.id}` +
      `&userID=${orderData.customerID}$type=${orderData.contentType}&videoTitle=${orderData.video.title}` +
      `&attachments=${attachmentsExist}&preview=${orderData.video.preview > 0}&orderID=${orderData.orderId}`);
  };

  const goToWebAR = () => {
    fetchOrderAttachments();
    //navigate(`/editor?videoUrl=${orderData.video.video_url}&videoID=${orderData.video.id}`);
    navigate(`/webareditor`);
  };


  const setPrice = () => {
    // Use window.confirm for a simple confirmation dialog
    const confirmed = window.confirm('Are you sure you want to set this as the price for the order?');

    if (confirmed) {
      // User clicked OK, proceed with setting the price
      sendPriceToServer();
    } else {
      // User clicked Cancel, do nothing
    }
  };

  const sendPriceToServer = async () => {
    try {
      const response = await fetch(`https://api-dev.medosphere.com/video/orders/${orderData.id}/cost`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          cost: calculateCost().toFixed(2),
        }),
      });

      if (response.ok) {
        // Handle success
        console.log('Price set successfully');
      } else {
        // Handle error
        console.error('Failed to set price');
      }
    } catch (error) {
      console.error('Error while setting price', error);
    }
  };

  return (
    <div className="order">
      <div className="order-header">
        <div className="top-nav">
          <div className="logo-container">
            <Link to="/dashboard">
              <LogoInline />
            </Link>
          </div>
          <ProfileButton
            isDropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            userImage={userImage}
            isValidURL={isValidURL}
            navigate={navigate}
            openModal={openModal}
            userID={userID}
            userEmail={userEmail}
            userName={userName}
          />
        </div>
        <div className='title-container'>
          <h1 className='page-title' style={{color: 'black'}}>Dashboard</h1>
          <div className="calendar">
            <a href="/">
              <FontAwesomeIcon icon="calendar" style={{ color: 'black', paddingLeft: '20px' }} />
            </a>
            <span className="App-link" style={{color: 'black'}}> {currentDate}</span>
          </div>
        </div>
      </div>
      <div className="order-content" style={{ color: "black" }}>
        <div className="order-content-header">
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black', }}>
            <h1 style={{ margin: 0 }}>{'< All Orders'}</h1>
          </Link>
          <div>
            Set a price and send offer to client.
          </div>
        </div>
        <div className="order-content-details">
          <div className='order-info'>
            <div className='status-container'>
              <div className={"status-button " + orderData.status}>
                {orderData.status.charAt(0).toUpperCase() + orderData.status.slice(1)}
              </div>
              <div className="status-bar">
                ID number:
                <div>{orderData.orderId}</div>
              </div>
            </div>
            <div className="order-options-container">
              <button
                onClick={() => handleOptionClick('Requirements')}
                className={`smallButtonDash ${selectedOption === 'Requirements' ? 'selected' : ''}`}
              >
                Requirements
              </button>
              <button
                onClick={() => handleOptionClick('ContentDetails')}
                className={`smallButtonDash ${selectedOption === 'ContentDetails' ? 'selected' : ''}`}
              >
                Content Details
              </button>
              <button
                onClick={() => handleOptionClick('Attachments')}
                className={`smallButtonDash ${selectedOption === 'Attachments' ? 'selected' : ''}`}
              >
                Attachments
              </button>
              <button
                onClick={() => handleOptionClick('Notes')}
                className={`smallButtonDash ${selectedOption === 'Notes' ? 'selected' : ''}`}
              >
                Notes
              </button>
            </div>
            {selectedOption === "Requirements" && (
              <div className="order-details" style={{ overflowY: 'auto' }}>
                <ol className="circle-list">
                  <li style={{fontWeight: 'bold'}}>{'How will you use this order? (Optional)'}</li>
                  <p style={{ paddingLeft: '2rem'}}>
                    {orderData.how_use || '-- N/A --'}
                  </p>
                  <li style={{fontWeight: 'bold'}}>{'Does the video need blurring? (Optional)'}</li>
                  <p style={{ paddingLeft: '2rem' }}>
                    {orderData.blurring ? 'Yes' : 'No'}
                  </p>
                  <li style={{fontWeight: 'bold'}}>{'How will you use this video? (Optional)'}</li>
                  <p style={{ paddingLeft: '2rem' }}>
                    {orderData.how_use || '-- N/A --'}
                  </p>

                  <li style={{fontWeight: 'bold'}}>{'Please add your script for your video. (Optional)'}</li>
                  <p style={{ paddingLeft: '2rem' }}>
                    {orderData.script || '-- N/A --'}
                  </p>
                </ol>
              </div>
            )}
            {selectedOption === "ContentDetails" && (
              <div className="order-details" style={{ overflowY: 'auto' }}>
                <div className="Content-Details" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                  <div>
                    <div>
                      <strong className="order-strong">Content Title:</strong>
                      <div>{orderData.video.title || '-- N/A --'}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong className="order-strong">Date Uploaded:</strong>
                      <div>{orderData.video.created_at || '-- N/A --'}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong className="order-strong">Content Description:</strong>
                      <div style={{ width: '100%' }}>{orderData.video.content || '-- N/A --'}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong className="order-strong">Type:</strong>
                      <div style={{ width: '100%' }}>{'-- N/A --'}</div>
                    </div>
                  </div>
                  <div>
                    <div style={{wordBreak: 'break-word'}}>
                      <strong className="order-strong">Content URL:</strong>
                      <div>
                        <a style={{ width: 'auto', color: '#266DF5' }} target="_blank" href={orderData.video.video_url} rel="noreferrer">
                          {orderData.video.video_url || '-- N/A --'}
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}
            {selectedOption === "Attachments" && (
              <div className="order-details" style={{ overflowY: 'auto' }}>
                <div className="Content-Details">
                  {/* Images */}
                  <div>
                    <strong className="order-strong">Images:</strong>
                    {orderData.attachments
                      .filter((attachment: { type: string; }) => attachment.type === 'image')
                      .map((imageAttachment: ImageAttachment, index: React.Key | null | undefined) => (
                        <ul key={index}>
                          <li>
                            <a style={{ color: 'black' }} href={imageAttachment.url} target="_blank" rel="noopener noreferrer">
                              {imageAttachment.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                    {orderData.attachments.filter((attachment: { type: string; }) => attachment.type === 'image').length === 0 && <p>-- N/A --</p>}
                  </div>
                  {/* Videos */}
                  <div>
                    <strong className="order-strong">Videos:</strong>
                    {orderData.attachments
                      .filter((attachment: { type: string; }) => attachment.type === 'video')
                      .map((videoAttachment: VideoAttachment, index: React.Key | null | undefined) => (
                        <ul key={index}>
                          <li>
                            <a style={{ color: 'black' }} href={videoAttachment.url} target="_blank" rel="noopener noreferrer">
                              {videoAttachment.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                    {orderData.attachments.filter((attachment: { type: string; }) => attachment.type === 'video').length === 0 && <p>-- N/A --</p>}
                  </div>
                  {/* Other Files */}
                  <div>
                    <strong className="order-strong">Other Files:</strong>
                    {orderData.attachments
                      .filter((attachment: { type: string; }) => attachment.type !== 'image' && attachment.type !== 'video')
                      .map((otherAttachment: OtherAttachment, index: React.Key | null | undefined) => (
                        <ul key={index}>
                          <li>
                            <a style={{ color: 'black' }} href={otherAttachment.url} target="_blank" rel="noopener noreferrer">
                              {otherAttachment.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                    {orderData.attachments.filter((attachment: { type: string; }) => attachment.type !== 'image' && attachment.type !== 'video').length === 0 && <p>-- N/A --</p>}
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Notes" && (
              <div className="order-details" style={{ overflowY: 'auto' }}>
                <div className="Content-Details">
                  <div> 
                    <strong className="order-strong">Client Notes:</strong>
                    <p>
                      {orderData.notes || '-- N/A --'}
                    </p>
                  </div>
                  <div>
                    <strong className="order-strong">Editor Notes:</strong>
                    <p>
                      -- N/A --
                    </p>
                  </div>

                </div>

              </div>
            )}
          </div>
          <div className="pricing-container" style={{ overflowY: 'auto' }}>
          {orderData.status === 'open' ? (
              <>
                <h1>Order Offer</h1>
                <div>Sending offer to:</div>
                <div className="offer-sender" style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%', marginRight: '10px', marginBottom: 0 }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={orderData.customerImg} alt="Client" />
                  </div>
                  <div>{orderData.customer}</div>
                </div>
              </>
            ) : (
              <>
                <h1>Current Order</h1>
                <div>Offer Sent To:</div>
                <div className="offer-sender" style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%', marginRight: '10px', marginBottom: 0 }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={orderData.customerImg} alt="Client" />
                  </div>
                  <div>{orderData.customer}</div>
                </div>
                <UploadButton 
                    // Pass the necessary props to UploadButton component
                    videoTitle={'order'+ orderData.orderId + '.mp4'}
                    custID={orderData.customerID}
                    orderID={orderData.orderId}
                  />
              </>
            )}
            {['open', 'new'].includes(orderData.status) && (
              <>
                <h2>Order Price</h2>
                <div>
                  <label className="order-duration" htmlFor="durationInput">Duration in minutes: </label>
                </div>
                <input
                  type="number"
                  id="durationInput"
                  name="durationInput"
                  min="0"
                  value={duration}
                  onChange={(e) => setDuration(parseInt(e.target.value, 10))}
                  style={{ backgroundColor: '#EDF4F9', color: 'black', textAlign: 'right' }}
                />
                <div>
                  <div>
                    <p>
                      x {ratePerMinute}
                    </p>
                  </div>
                  {duration >= 0 && (
                    <div style={{ color: 'black', }}>
                      {"Total Cost"}
                      <div>
                        ${calculateCost().toFixed(2)}
                      </div>
                    </div>
                  )}
                </div>
                <button style={{ color: 'white', backgroundColor: '#266DF5', width: '100%' }} onClick={setPrice}>Set Price & Send Offer</button>
              </>
            )}
          </div>
        </div>
        <button className="editorButton" onClick={goToEditor}>
          Go to Editor
        </button>
        <button className="editorButton" onClick={goToWebAR}>
          Go to Web AR
        </button>
      </div>
      <LogoutDialog isModalOpen={isModalOpen} closeModal={closeModal} />
      <div style={{ color: 'grey', position: 'absolute', bottom: '1%', right: '9%', fontSize: '17px' }}>
        Web App 1.0
      </div>
    </div>
  );
}
