import "./WebARComponent.css"

import React, { useEffect, useRef, useState } from "react"
import { Webcam, Player, Module, Effect, Dom } from "@banuba/webar"
import FaceTracker from "@banuba/webar/face_tracker.zip"
import wasm from "@banuba/webar/BanubaSDK.wasm"
import simd from "@banuba/webar/BanubaSDK.simd.wasm"
import data from "@banuba/webar/BanubaSDK.data"
import { ImageCapture /* ... */ } from "@banuba/webar"
import glassesEffect from "../assets/effects/glasses_Banuba.zip"

// Find more about available modules:
// https://docs.banuba.com/face-ar-sdk-v1/generated/typedoc/classes/Module.html


function WebARComponent() {
  const ref = useRef({})
  const [isEffectVisible, setEffectVisible] = useState(true);
  const token = process.env.REACT_APP_BANUBA_CLIENT_TOKEN;

  const toggleEffectVisibility = () => {
    setEffectVisible(!isEffectVisible);
  };

  // componentDidMount
  useEffect(() => {
    const webcam = (ref.current.webcam ??= new Webcam())
    const promise = (ref.current.player ??= Player.create({
      clientToken: token,
      /**
       * By default BanubaSDK.js tries to loads BanubaSDK.wasm and BanubaSDK.data files relative to itself.
       * Since the BanubaSDK.js will be bundled to something like `static/js/[name].[hash].js` during a build
       * and the BanubaSDK.wasm and BanubaSDK.data files may not lay next to the bundle file
       * we have to tell the BanubaSDK where it can find these BanubaSDK.wasm and BanubaSDK.data files.
       * @see {@link https://docs.banuba.com/generated/typedoc/globals.html#sdkoptions} further information}
       */
      locateFile: {
        "BanubaSDK.wasm": wasm,
        "BanubaSDK.simd.wasm": simd,
        "BanubaSDK.data": data,
      },
    }).then((player) =>
      player.addModule(new Module(FaceTracker)).then(() => {
        player.use(webcam)
        if (isEffectVisible) {
          player.applyEffect(new Effect(glassesEffect));
        }
        Dom.render(player, "#webar")
      })
    ))

    return () => {
      webcam.stop()
      Dom.unmount("#webar")
    }
  }, [isEffectVisible]);

  return (
    <div className="WebApp">
      <header className="WebApp-header">
        <div id="webar">
        </div>
      </header>
    </div>
  )
}

export default WebARComponent