import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
// import { FaVolumeUp, FaVolumeOff } from 'react-icons/fa'
import './channel.css';

export default function Channel() {
    const playerRef = useRef<any>(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0);
    const [muted, setMuted] = useState(volume === 0);
    // const [played, setPlayed] = useState(0);
    // const [playerWidth, setPlayerWidth] = useState(0);
    // const [loaded, setLoaded] = useState(false);
    const [playbackURL, setPlaybackURL] = useState('');

    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        const playback_url = queryParam.get('playback_url');

        if (playback_url != null && playback_url !== '') {
            setPlaybackURL(decodeURIComponent(playback_url));
        }
    }, []);

    // const url = "https://d3nfhghp4g6lu4.cloudfront.net/ivs/v1/031924585246/MiWbtubwsg1P/2023/8/29/17/25/LlCgMXNVfV0B/media/hls/1080p60/playlist.m3u8";
    // const togglePlay = () => {
    //     setPlaying((prev) => !prev);
    // };

    // const handleVolumeChange = (e: any) => {
    //     setVolume(parseFloat(e.target.value));
    // };

    // const handleSeekChange = (e: any) => {
    //     const value = parseFloat(e.target.value);
    //     setPlayed(value);
    //     playerRef.current.seekTo(value);
    // };

    // const formatTime = (seconds: number) => {
    //     const h = Math.floor(seconds / 3600);
    //     const m = Math.floor((seconds % 3600) / 60);
    //     const s = Math.floor(seconds % 60);
    //     return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
    // };

    const seekLive = () => {
        const maxDuration = playerRef.current.getDuration();
        const liveSeek = maxDuration - 3;
        playerRef.current.seekTo(liveSeek);
        setPlaying(true);
    };

    const setMute = () => {
        if (volume === 0) setVolume(1);
        setMuted((prev) => !prev);
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        }}>
            <div style={{
                display: 'flex',
                maxWidth: '1200px',
                maxHeight: '800px',
                minHeight: '680px',
                height: '100%',
                width: '100%',
                position: 'relative',
            }}>
                <ReactPlayer
                    ref={playerRef}
                    url={playbackURL}
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    controls={true}
                    pip={false}
                    // onProgress={({ played }) => setPlayed(played)}
                    onReady={(e: ReactPlayer) => {
                        // const wrapper = playerRef.current.wrapper;
                        // get width and height and dimensions from wrapper
                        // const { width, height } = wrapper.getBoundingClientRect();
                        // setPlayerWidth(width);
                        // setLoaded(true);
                        setPlaying(true);
                    }}
                    width="100%"
                    height="100%"
                />
            </div>
            <div>
                <button className='channel-buttons black' onClick={()=>setPlaying((prev) => !prev)}>{playing ? 'Pause' : 'Play'}</button>
                <button className='channel-buttons red' onClick={()=>seekLive()}>Seek LIVE</button>
                <button className='channel-buttons black' onClick={()=>setMute()} >{muted ? 'UnMute' : 'Mute'}</button>
            </div>
            {/* {
                loaded && (
                    <div className="controls" style={{width: playerWidth.toString() + 'px'}}>
                        <button onClick={togglePlay}>
                            {playing ? "Pause" : "Play"}
                        </button>
                        <input
                            type="range"
                            min={0}
                            max={0.999}
                            step="any"
                            value={played}
                            onChange={handleSeekChange}
                        />
                        <div style={{margin: '0 10px'}}>
                        { playerRef && playerRef?.current?.getDuration() 
                            ?
                            <>
                                {formatTime(played * playerRef?.current?.getDuration())} / {formatTime(playerRef.current.getDuration())}
                            </>
                            : <div>00:00 / 00:00</div>
                        }
                        </div> 

                        
                        <div style={{ width: '150px', display: 'flex', justifyContent: 'flex-start'}}>
                            <div style={{width: '20px', height: '100%', display: 'flex', justifyContent: 'center'}}>
                                { volume === 0 ? <FaVolumeOff /> : <FaVolumeUp />}
                            </div>
                            <input
                                type="range"
                                min={0}
                                max={1}
                                step="any"
                                value={volume}
                                onChange={handleVolumeChange}
                            />
                        </div>
                    </div>
                )
            } */}

        </div>
    );
}


// create style sheet
