import React, { useRef, useState } from 'react';
import { reviewApiEndpoint, startApiEndpoint, uploadInitEndpoint } from '../config';

interface UploadButtonProps {
  videoTitle: string;
  custID: number;
  orderID: number;
}

const UploadButton: React.FC<UploadButtonProps> = ({ videoTitle, custID, orderID }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false); // State to manage upload status
  const accessToken = localStorage.getItem('accessToken');

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true); // Set uploading state to true when file upload starts
      await uploadVideo(file);
      setUploading(false); // Set uploading state to false when file upload completes
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };
  const setStart = async () => {
    try {
      // tried with and without headers and bodies, response doesnt change
      const response = await fetch(startApiEndpoint(orderID), {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

    } catch (error) {
      console.error('Error initializing upload:', error);
    }
  };
  
  const uploadVideo = async (file: File) => {
    // console.log("start upload of file: " + file.name);
    try {
      // console.log('Getting URLS');
      const signedUrlData = await getSignedUrl();
      // console.log('Obtained URL Data: ' + signedUrlData.url + " and " + signedUrlData.public_url);

      if (!signedUrlData) {
        throw new Error('No signed URL received');
      }

      const presignedUrl = signedUrlData.url;
      const publicUrl = signedUrlData.public_url;

      // console.log("Uploading Video To: " + presignedUrl);

      await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      await sendReview(publicUrl);
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };

  const getSignedUrl = async () => {
    try {
      setStart();
      // console.log("Fetching from endpoint: " + uploadInitEndpoint);
      // console.log(custID + ' ' + videoTitle);
      const response = await fetch(uploadInitEndpoint, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user_id: custID,
          type: 'video',
          file_name: videoTitle,
          title: videoTitle,
          content: '',
          preview: false,
          attachment: true,
        }),
      });

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  const sendReview = async (publicUrl: string) => {
    try {
      const uri = reviewApiEndpoint(orderID);

      // console.log('Sending review to: ' + uri);

      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          video_url: publicUrl,
          preview_url: publicUrl, // Using the same URL for preview for now
        }),
      });

      // console.log(response);

      // Handle response if needed
    } catch (error) {
      console.error('Error sending review:', error);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="video/mp4" // Specify the accepted file types
      />
      <button style={{ color: 'white', backgroundColor: '#266DF5', width: '100%', marginTop: 'auto', alignSelf: 'flex-end center' }}
        onClick={handleUploadButtonClick}>
        Upload Video To Customer
      </button>
      {uploading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ background: 'white', padding: '20px', borderRadius: '5px', color: 'black' }}>
            <p>Uploading...</p>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadButton;
