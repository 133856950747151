import React from 'react'
import MedosphereLogoInline from '../../assets/images/medosphere_inline.svg'
import './logo.css'

const LogoInline = () => (
  <div className='logo logo--inline'>
    <img src={MedosphereLogoInline} alt='powered by curiosity' />
    <span>Studio</span>
  </div>
)

export default LogoInline