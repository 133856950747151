// TableHeader.tsx
import React from 'react';

interface CustomerTableHeaderProps {
  handleSortChange: (field: string) => void;
  sortBy: string;
  sortOrder: string;
}

const CustomerTableHeader: React.FC<CustomerTableHeaderProps> = ({ handleSortChange, sortBy, sortOrder }) => (
  <thead style={{ backgroundColor: 'transparent'}}>
    <tr style={{ backgroundColor: 'transparent'}}>
        <th className="table-btn-head"  onClick={() => handleSortChange('status')}>
        Status&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'status' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
        </th>
        <th className="table-btn-head"  onClick={() => handleSortChange('orderId')}>
        Order ID&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'orderId' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
        </th>
         <th className="table-btn-head"  onClick={() => handleSortChange('customer')}>
         Customer&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'customer' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
         </th>
         <th  className="table-btn-head"  onClick={() => handleSortChange('contentType')}>
         Content type&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'contentType' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
         </th>
         <th className="table-btn-head"  onClick={() => handleSortChange('cost')}>
         Cost&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'cost' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
         </th>
         <th className="table-btn-head"  onClick={() => handleSortChange('dueDate')}>
         Due date&nbsp;&nbsp;&nbsp;&nbsp;{sortBy === 'dueDate' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
         </th>
         <th className="table-btn-head" >
         Actions
         </th>
    </tr>
  </thead>
);

export default CustomerTableHeader;
