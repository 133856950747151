import React from 'react'
import { LogoInline } from '../'
import './screenContainer.css'

interface ScreenContainerProps {
  children: React.ReactNode
}

const ScreenContainer = ({ children }: ScreenContainerProps) => {
  return (
    <div className="screen-container">
      <div className="screen-logo">
        <LogoInline />
      </div>
      <div className="screen-body">
        <div className="screen-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default ScreenContainer