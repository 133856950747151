import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './verifyLicense.css';

export default function VerifyLicense() {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to the profile page when the component is loaded
        navigate('/dashboard');
    }, [navigate]); // The empty dependency array ensures that this effect runs only once

    return (
        <div className="App">
            {/* You can include any content you want in this component */}
        </div>
    );
}