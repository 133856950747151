import React, { useEffect, useState } from "react";
import "./page-loader.css";
import loadingImg from "../assets/images/loadingGrey.svg";

export const PageLoader: React.FC = () => {

    const loaderStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Adjust this to control the height of the loader container
    };

    const imgStyle = {
        maxWidth: "5%", // Adjust this to control the size of the image
        animation: "spin 2s linear infinite", // CSS animation to make the image spin
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const handleLoad = () => {
            // Once the page is fully loaded, remove the loading screen
            setIsLoading(false);
        };

        // Add event listener for page load
        window.addEventListener("load", handleLoad);

        return () => {
            // Cleanup: remove event listener
            window.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
            <div className="loader" style={loaderStyle}>
                <img src={loadingImg} alt="Loading..." style={imgStyle}/>
            </div>
    );
};
