// TableHeader.tsx
import React from 'react';

interface TableHeaderProps {
  handleSortChange: (field: string) => void;
  sortBy: string;
  sortOrder: string;
  isAdmin: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({ handleSortChange, sortBy, sortOrder, isAdmin }) => (
  <thead style={{ backgroundColor: 'transparent'}}>
    <tr style={{ backgroundColor: 'transparent'}}>
        <th>
          <button
          className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
          style={{minWidth: '50%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('status')}>
              Status {sortBy === 'status' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
          </button>
        </th>
        <th>
          <button 
          className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
          style={{minWidth: '35%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('orderId')}>
              Order ID {sortBy === 'orderId' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
          </button>
        </th>
         <th>
           <button 
           className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
           style={{minWidth: '37.5%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('customer')}>
             Customer {sortBy === 'customer' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
           </button>
         </th>
         <th>
           <button 
           className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
           style={{minWidth: '39.5%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('contentType')}>
             Content Type {sortBy === 'contentType' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
           </button>
         </th>
         <th>
           <button 
           className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
           style={{minWidth: '36.5%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('cost')}>
             Cost {sortBy === 'cost' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
           </button>
         </th>
         <th>
           <button 
           className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
           style={{minWidth: '35%', width: '100%', maxWidth: '100%',margin: 0 }} onClick={() => handleSortChange('dueDate')}>
             Due Date {sortBy === 'dueDate' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
           </button>
         </th>
         <th>
           <button 
           className={`${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`} 
           style={{minWidth: '50%', width: '100%', maxWidth: '100%', margin: 0 }} onClick={() => handleSortChange('assignee')}>
             Assignee {sortBy === 'assignee' ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
           </button>
         </th>
         <th><button className={`action-btn ${isAdmin ? 'admin-theme-sorting' : 'user-theme-order-sorting'}`}  style={{minWidth: '42.5%', width: '100%', maxWidth: '100%', margin: 0 }}>Action</button></th>
    </tr>
  </thead>
);

export default TableHeader;
