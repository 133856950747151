import React from "react";
import './button.css'

interface ButtonProps {
  type?: 'primary' | 'outline' | 'underline'
  text: string
  onClick: () => void
}

const Button = ({
  type = 'primary',
  text,
  onClick,
  ...props
}: ButtonProps) => {
  let classNames = ''

  if (type === 'outline') classNames = 'button--secondary'
  else if (type === 'underline') classNames = 'button--underline'

	return (
		<button
      className={`button--base ${classNames}`}
      {...props}
      onClick={onClick}
    >
      {text}
    </button>
  )
};

export default Button;
