import React from 'react';
// import { useLocation } from 'react-router-dom';

interface DoctorProfileProps {
  user: {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    stateIssued: string;
    permitNumber: string;
    npiNumber: string;
  };
}

//Commented out till page is needed

const DoctorProfile: React.FC<DoctorProfileProps> = () => {
  // const location = useLocation();
  // const { user } = location.state || { user: { username: '', email: '', firstName: '', lastName: '', permitNumber: '', npiNumber: '', stateIssued: '' } };

  return (
    <div>
      {/* <h2>Doctor Profile Page</h2>
      <div>
        <strong>Username:</strong> {user.username}
      </div>
      <div>
        <strong>Email:</strong> {user.email}
      </div>
      <div>
        <strong>First Name:</strong> {user.firstName}
      </div>
      <div>
        <strong>Last Name:</strong> {user.lastName}
      </div>
      <div>
        <strong>State Issued:</strong> {user.stateIssued}
      </div>
      <div>
        <strong>Permit Number:</strong> {user.permitNumber}
      </div>
      <div>
        <strong>NPI Number:</strong> {user.npiNumber}
      </div>
      <p>Additional information or components can be added here.</p> */}
    </div>
  );
};

export default DoctorProfile;