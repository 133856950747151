import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface Video {
  id: number;
  title: string;
  content: string;
  video_url: string;
  thumbnail_url: string;
  preview: string;
  created_at: string;
}

interface Attachment {
  url: string;
  name: string;
  type: string;
}

interface ProductOrder {
  orderId: number;
  customer: string;
  customerImg: string;
  customerID: string;
  contentType: string;
  dueDate: string;
  status: string;
  state: string;
  cost: string;
  assignee: string;
  blurring: boolean;
  bigger: boolean;
  script: string;
  attachments: Attachment[];
  video: Video;
}

interface ProductOrderItemProps {
  order: ProductOrder;
  onAssignClick: () => void;
}

const ProductOrderItem: React.FC<ProductOrderItemProps> = ({ order, onAssignClick }) => {
  const { orderId, customer, contentType, dueDate, status, assignee } = order;
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userRole = user?.role;

  const isAdmin = user?.role === 'admin';

  const handleViewOrderClick = () => {
    navigate(`/order?orderID=${orderId}`, { state: { order } });
  };

  const handleAssignClick = () => {
    const confirmAssign = window.confirm('Are you sure you want to assign this order to yourself?');
    if (confirmAssign) {
      onAssignClick();
    }
  };

  const renderActionButton = () => {
    if (userRole === 'admin') {
      return (
        <td>
          <button
            style={{ width: '8em', backgroundColor: 'transparent' }}
            className="assign-btn"
          >
            {assignee}
          </button>
        </td>
      );
    } else if (assignee === 'Not Assigned') {
      return (
        <td>
          <button
            style={{ width: '5em', backgroundColor: 'white' }}
            className="assign-btn"
            onClick={handleAssignClick}
          >
            Assign
          </button>
        </td>
      );
    } else {
      return null; // Hide action button for non-admin users with an assignee
    }
  };

  return (
    <tr>
      <td className={`first ${isAdmin ? 'admin-theme-order' : 'user-theme-order'}`}>
        <button className={"status-display " + status} onClick={handleViewOrderClick}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </button>
      </td>
      <td>{orderId}</td>
      <td>{customer}</td>
      <td>{contentType}</td>
      <td>{order.cost}</td>
      <td>{dueDate}</td>
      {renderActionButton()}
      {/* if status is new do not render */}
      {status !== 'new' && (
        <td className="view-btn" onClick={handleViewOrderClick}>
          <div style={{padding:10, backgroundColor:'#EBF2FF', borderRadius: 8, fontWeight: 500}}>
          View Order
          </div>
        </td>
      )}
    </tr>
  );
};

export default ProductOrderItem;
