import './index.css';
import CreativeEditorSDK from '@cesdk/cesdk-js';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages/editing/editor.css'
import { reviewApiEndpoint, uploadInitEndpoint, startApiEndpoint } from './config';

export default function CreativeEditorSDKComponent() {
  const cesdk_container = useRef(null);
  const [cesdk, setCesdk] = useState(null);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const videoUrl = new URLSearchParams(window.location.search).get('videoUrl')?.replace(/ /g, '%20');
  const videoTitleParam = new URLSearchParams(window.location.search).get('videoTitle');
  const videoTitle = videoTitleParam ? decodeURIComponent(videoTitleParam) : null;
  const custID = parseInt(new URLSearchParams(window.location.search).get('userID'), 10);
  const orderID = parseInt(new URLSearchParams(window.location.search).get('orderID'), 10);

  function getFileExtension(url) {
    const matches = url.match(/\.([a-z0-9]+)(?:[\?#]|$)/i);
    return matches ? matches[1] : null;
  }

  const videoFileType = getFileExtension(videoUrl);
  const vidTitleComplete = videoTitle + '.' + videoFileType;

  const [exportUrls, setExportUrls] = useState({ public_url: null, url: null });

  useEffect(() => {
    if (!cesdk_container.current) return;

    const config = {
      // license: '',
      baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-js/1.18.1/assets',
      locale: 'en',
      theme: 'dark',
      role: 'Creator',
      ui: {
        elements: {
          view: 'advanced',
          panels: {
            //settings: true,
            inspector: {
              show: true,
              position: 'left'
            },
            assetLibrary: {
              show: true,
              position: 'left'
            },
          },
          navigation: {
            position: 'top',
            action: {
              back: true,
              save: true,
              load: true,
              download: true,
              export: true
            }
          }
        }
      },
      blocks: {
        opacity: true, // true  or false
        transform: true, // true  or false
        '//ly.img.ubq/graphic': {
          adjustments: true, // true  or false
          filters: true, // true  or false
          effects: true, // true  or false
          blur: true, // true  or false
          crop: true
        },
        '//ly.img.ubq/page': {
          manage: true,
          format: true,
          maxDuration: 30 * 60,
        }
      },
      callbacks: {
        onUpload: 'local',
        onSave: (scene) => {
          const element = document.createElement('a');
          const base64Data = btoa(unescape(encodeURIComponent(scene)));
          element.setAttribute(
            'href',
            `data:application/octet-stream;base64,${base64Data}`
          );
          element.setAttribute(
            'download',
            `cesdk-${new Date().toISOString()}.scene`
          );

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        },
        onLoad: 'upload',
        onDownload: 'download',
        onExport: async () => {
          try {
            // Export scene as mp4 video
            const mimeType = 'video/mp4';

            // Progress callback to track the export progress
            const progressCallback = (renderedFrames, encodedFrames, totalFrames) => {
              // console.log(
              //   'Rendered',
              //   renderedFrames,
              //   'frames and encoded',
              //   encodedFrames,
              //   'frames out of',
              //   totalFrames
              // );
            };

            // Export the video to a Blob, which can be uploaded to the server
            const blob = await instance.engine.block.exportVideo(
              instance.scene,
              mimeType,
              progressCallback,
              {}
            );

            // Download the video to the user's device
            const anchor = document.createElement('a');
            anchor.href = URL.createObjectURL(blob);
            anchor.download = vidTitleComplete;
            anchor.click();

            // Upload the video to the server and get the public URL
            const publicUrl = await uploadVideo(blob);

            // Proceed with the review API call

            const uri = reviewApiEndpoint(orderID);

            const response = await fetch(uri, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
                accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                video_url: publicUrl,
                preview_url: publicUrl, // We are using the same URL for the preview for now
              }),
            });
          } catch (error) {
            console.error('Error during export file:', error);
          }
        },
        onBack: (action) => {
          navigate(-1); // This will go back one step in the navigation history
        },
      },
    };

    let cleanedUp = false;
    let instance;

    if (typeof CreativeEditorSDK.create === 'function') {
      CreativeEditorSDK.create(cesdk_container.current, config).then(
        async (_instance) => {
          instance = _instance;
          if (cleanedUp) {
            instance.dispose();
            return;
          }

          await Promise.all([
            instance.addDefaultAssetSources(),
            instance.addDemoAssetSources({ sceneMode: 'Video' }), // Set sceneMode to 'Video'
          ]);

          await instance.engine.scene.createFromVideo(videoUrl);

          setCesdk(instance);

        }
      );
    }

    const setStart = async () => {
      try {
        // tried with and without headers and bodies, response doesnt change
        const response = await fetch(startApiEndpoint(orderID), {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

      } catch (error) {
        console.error('Error initializing upload:', error);
      }
    };

    const getSignedUrl = async () => {
      try {
        setStart();
        // Get the signed URL from the upload init API
        const response = await fetch(uploadInitEndpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            user_id: custID,
            type: 'video',
            file_name: vidTitleComplete,
            title: videoTitle,
            content: "",
            preview: false,
            attachment: true,
          }),
        });

        const responseData = await response.json();

        return responseData;

      } catch (error) {
        console.error('Error initializing upload:', error);
      }
    };

    const uploadVideo = async (file) => {
      try {
        // Get the signed URL from the server first
        const signedUrl = await getSignedUrl();

        if (!signedUrl) {
          throw new Error('No signed URL received');
        }
        // Extract the presigned URL and the public URL
        const presignedUrl = signedUrl.url; // The presigned URL to upload the video to the server
        const publicUrl = signedUrl.public_url; // The public URL to access the video after the upload, we need it for the order review API call

        // Upload the video to the presigned URL

        await fetch(presignedUrl, {
          method: 'PUT', // Use the PUT method to upload the video
          body: file, // This is the blob with the video data
          headers: {
            'Content-Type': 'video/mp4', // Set the content type to video/mp4
          },
        });

        // Return the public URL to the caller
        return publicUrl;
      } catch (error) {
        console.error('Error uploading video to presigned URL:', error);
      }
    };
  }, [cesdk_container, navigate, setCesdk, exportUrls]);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <div
        id="cesdk_container"
        ref={cesdk_container}
        style={{ width: '100%', height: '100%' }}
      ></div>
    </div>
  );
}