// profile.tsx
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react'; // Import the necessary Auth0 hook
import logoTop from '../../assets/images/medosphere-studio-logo.svg';
import './profile.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoutDialog from '../../components/LogoutDialog';
import ProfileButton from '../../components/ProfileButton';

interface ProfileProps {
  currentUser: {
    username: string;
    email: string;
    // Add more fields as needed
  };
}

const Profile: React.FC<ProfileProps> = ({ currentUser }) => {
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0(); // Use Auth0 hook

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const userData = useAuth0().user;
  const userId = userData?.sub?.split('|')[1];
  const userImage = userData?.picture;

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isValidURL = (url: string | URL) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    // Check if the user is authenticated and Auth0 user information is available
    if (isAuthenticated && auth0User) {
      // Assuming that the email is available in the Auth0 user object
      setUserEmail(currentUser.email);
    }
  }, [isAuthenticated, auth0User, currentUser.email]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile">
      <div className="profile-header">
        <div className="top-nav">
          <div className="logo-container">
            <Link to="/dashboard">
              <img src={logoTop} alt="logo" />
            </Link>
          </div>
          {/* <ProfileButton
            isDropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            userImage={userImage}
            isValidURL={isValidURL}
            navigate={navigate}
            openModal={openModal}
          /> */}
        </div>
        <div className='title-container'>
          <h1 className='page-title'>Profile</h1>
          <div className="calendar">
            <a href="/">
              <FontAwesomeIcon icon="calendar" style={{ color: 'white', paddingLeft: '20px' }} />
            </a>
            <span className="App-link"> {currentDate}</span>
          </div>
        </div>
      </div>
      <div className="profile-content" style={{ color: 'white' }}>
        <div style={{flex: 1, padding: '1.5rem'}}>
          <strong>Email:</strong> {userEmail}
          <div></div>
          <strong>User ID:</strong> {userId}
        </div>
      </div>
      <LogoutDialog isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Profile;
