import React from 'react';
import { LogoInline } from '../../components';
import './landing.css';

export default function Landing() {
  const containerStyle: React.CSSProperties = {
    // backgroundImage: `url(${backGround})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // minHeight: '100vh',
  };

  return (
    <div className="App" style={containerStyle}>
      <div className="top-logo-landing">
        <LogoInline />
      </div>

      <header className="App-header">
      </header>
    </div>
  );
}