import React from 'react';
import { Link } from 'react-router-dom';
import { ScreenContainer, Logo, Button } from '../../components';
import './home.css';

export default function Home() {

  return (
    <ScreenContainer>
      <div className="home-content" >
        <Logo />
        {/* Button to navigate to the sign in/up page */}
        <div className="enter-button">
          <Link to="/login" style={{textDecoration: 'none', width: '30%'}}>
            <Button text="Enter" onClick={() => {}} />
          </Link>
        </div>
      </div>
    </ScreenContainer>
  );
}