import React from 'react';
import ProductOrderItem from './ProductOrderItem';

interface TableBodyProps {
  sortedOrders: any[];
  currentPage: number;
  itemsPerPage: number;
  handleAssignClick: (orderId: number) => void;
}

const TableBody: React.FC<TableBodyProps> = ({ sortedOrders, currentPage, itemsPerPage, handleAssignClick }) => {
  if (sortedOrders.length === 0) {
    return (
      <tbody>
        <tr style={{ padding: '10px', marginBottom: '10px' }}>
          <td colSpan={8} style={{ textAlign: 'center' }}>No orders found</td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody style={{ backgroundColor: 'transparent' }}>
        {sortedOrders
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((order) => (
            <ProductOrderItem
              key={order.orderId}
              order={order}
              onAssignClick={() => handleAssignClick(order.orderId)}
            />
          ))}
      </tbody>
    );
  }
};

export default TableBody;
