import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LogoInline } from '../../components';
import './upload.css';

const FileUpload = ({ onFileDrop }: any) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'Files',
    drop: (item: any, monitor) => {
      if (onFileDrop) {
        onFileDrop(item.files);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [onFileDrop]);

  const isActive = canDrop && isOver;
  let backgroundColor = '#f9f9f9';
  if (isActive) {
    backgroundColor = '#e9e9e9';
  } else if (canDrop) {
    backgroundColor = '#f0f0f0';
  }

  return (
    <div ref={drop} style={{ border: '2px dashed gray', margin: '0 20px', textAlign: 'center', backgroundColor, minWidth: 400, minHeight: 400 }}>
      {isActive ? 'Release to drop' : 'Drag file here'}
    </div>
  );
};

export default function Upload() {
  const onFileDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const formData = new FormData();
      formData.append('file', file);

      fetch('/presigned_url', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          // console.log('File uploaded successfully', data);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    });
  }, []);

  return (
    <div className="upload-container">
      <Link to="/dashboard" style={{ textDecoration: 'none', marginBottom: 20 }}>
        <LogoInline />
      </Link>
      <DndProvider backend={HTML5Backend}>
        <FileUpload onFileDrop={onFileDrop} />
      </DndProvider>
    </div>
  );
}
